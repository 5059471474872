
// Write your custom style. Feel free to split your code to several files
body {
  //color: blue;
}

// use it with swiper slider will fullwidth and adjust responsive
.slidevh {
  height:80vh;
}

@media only screen and (max-width: 600px) {
  .slidevh {
      height:30vh;
  }
}